<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions> </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <ViewPlanning v-if="id" :idShow="id" />
  </static-fullscreen-card>
</template>

<script>
import { statusChange, getAccess, autoHeightBlock } from "@/components/mixings";
export default {
  mixins: [statusChange, getAccess, autoHeightBlock],
  components: {
    ViewPlanning: () => import("./planning/planningForm"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      oldDoc: false,
      loading: true,
      showEditDialog: false,
      showLegend: true,
      title: "",
      url: "/mechti/objects",
      data: {},
    };
  },
  created() {
    this.loading = true;
    this.$root.title = "Объекты-планирование";
    this.id = this.idShow || Number(this.$route.params.id);
    this.permit = this.getAccess("menu.objects");
    this.fitchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    curValueDetail() {
      this.getValueDetail();
    },
  },
  computed: {
    editObject() {
      return this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
    showObject() {
      return this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
        inRole: -1,
      });
    },
  },
  methods: {
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = false;
    },
  },
};
</script>
